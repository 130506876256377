@use 'sass:list';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

%is-bold {
  font-weight: 700 !important;
}

b,
.has-text-bold {
  @extend %is-bold;
}

h1,
h2 {
  @include touch {
    overflow-wrap: break-word;
  }
}

$weight-black: 900;

// set line-heights corresponding to sizes (+ responsive)
@for $i from 1 through list.length($line-heights) {
  $lh: list.nth($line-heights, $i);

  .is-size-#{$i} {
    line-height: #{$lh};
  }

  @include mobile {
    .is-size-#{$i}-mobile {
      line-height: #{$lh};
    }
  }

  @include tablet {
    .is-size-#{$i}-tablet {
      line-height: #{$lh};
    }
  }

  @include touch {
    .is-size-#{$i}-touch {
      line-height: #{$lh};
    }
  }

  @include desktop {
    .is-size-#{$i}-desktop {
      line-height: #{$lh};
    }
  }

  @include widescreen {
    .is-size-#{$i}-widescreen {
      line-height: #{$lh};
    }
  }

  @include fullhd {
    .is-size-#{$i}-fullhd {
      line-height: #{$lh};
    }
  }
}
