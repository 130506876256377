//////////////////////////////////////// COLOURS

$white: #ffffff;
$black: #182023;

// $grey-darker: #232323;
$grey-dark: #323c45;
$grey: #6c757d;
$grey-light: #e4e7eb;
$grey-lighter: #f1f3f5;

$red: #ff0000;
$background: $white;
$background-light: #fcf4f6;

$dark: $grey-dark;
$light: $background-light;

$primary: #dd3150;
$primary-dark: #c82845;
$primary-darker: #b11934;
$primary-invert: $white;
$border: #fecfd7;

$custom-colors: (
  'primary-dark': (
    $primary-dark,
    $white,
  ),
);

//////////////////////////////////////// BULMA OVERWRITE

// body
$family-primary: 'Inter', sans-serif;

$body-background-color: $background;
$body-color: $black;
$body-size: 16px;

$radius-small: 8px;
$radius: 16px;
$radius-medium: 24px;
$radius-large: 40px;
$radius-rounded: 250px;

// navbar
$navbar-height: 6.25rem; // 100px
$navbar-item-img-max-height: 60px;
$navbar-background-color: $primary-dark;

$navbar-item-color: $white;
$navbar-item-hover-color: $white;
$navbar-item-hover-background-color: $primary-darker;
$navbar-item-active-color: $white;
$navbar-item-active-background-color: $primary-darker;

$navbar-dropdown-background-color: $primary-darker;
$navbar-dropdown-item-color: $white;
$navbar-dropdown-item-hover-color: $white;
$navbar-dropdown-item-active-background-color: transparent;
$navbar-dropdown-arrow: $white;
$navbar-dropdown-item-hover-background-color: $primary;
$navbar-dropdown-offset: 0;
$navbar-dropdown-border-top: none;
$navbar-dropdown-boxed-radius: $radius-small;
$navbar-dropdown-boxed-shadow: 0 8px 8px rgba(24, 32, 35, 0.1);

// layout
$gap: 24px;
$column-gap: 24px;
$block-spacing: 20px;
$section-padding: 40px 0;
$section-padding-desktop: 60px 0;
$section-padding-medium: 80px 0;
$section-padding-large: 120px 0;
$footer-padding: 50px 0 30px;

// typo + sizes
$size-1: 4.5rem; // 72px
$size-2: 3rem; // 48px
$size-3: 2rem; // 32px
$size-4: 1.75rem; // 28px
$size-5: 1.375rem; // 22px
$size-6: 1.1875rem; // 19px
$size-7: 1rem; // 16px
$size-8: 0.875rem; // 14px

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8;
$size-small: $size-8;
$size-normal: $size-7;
$size-large: $size-6;

$lh-1: 1.05;
$lh-2: 1.15;
$lh-3: 1.2;
$lh-4: 1.4;
$lh-5: 1.3;
$lh-6: 1.4;
$lh-7: 1.333;
$lh-8: 1.21;
$line-heights: $lh-1 $lh-2 $lh-3 $lh-4 $lh-5 $lh-6 $lh-7 $lh-8;

$spacing-values: (
  '0': 0,
  '1': 0.25rem,
  '2': 0.5rem,
  '3': 10px,
  '4': 20px,
  '5': 24px,
  '6': 32px,
  '7': 48px,
  '8': 60px,
  '9': 80px,
  '10': 120px,
  'auto': auto,
);

$content-heading-color: $black;
$title-weight: normal;

// component colors
$link: $primary;
$link-hover: $primary-darker;
$title-color: inherit;
$subtitle-color: $grey;

$footer-color: $white;
$footer-background-color: $grey-dark;
$footer-padding: 48px 0;

// form
$input-focus-box-shadow-size: 0;
$input-shadow: none;
$label-color: $grey;
$label-weight: normal;

// modals
$modal-content-width: 750px;
$modal-card-body-padding: 20px;
$modal-close-dimensions: 56px;
$modal-close-top: 6px;
$modal-close-right: 6px;
$modal-background-background-color: $white;
$modal-card-body-background-color: $grey-dark;
$modal-card-head-background-color: $grey-dark;
$modal-card-footer-background-color: $grey-dark;
$modal-card-title-color: $primary;
$modal-card-head-radius: 0;
$modal-card-foot-radius: 0;

// content
$content-blockquote-background-color: $white;
$content-blockquote-border-left: 0;
$content-blockquote-padding: 20px 0;
$tag-radius: $radius;

// box
$box-shadow: none;
$box-background-color: transparent;
$box-padding: 0;
$box-link-hover-shadow: none;
$box-link-active-shadow: none;
$box-radius: $radius;

// aside menu
$menu-item-color: $grey;
$menu-item-radius: 0;
$menu-list-link-padding: 8px 0 8px 12px;
$menu-item-hover-color: $black;
$menu-item-hover-background-color: $white;
$menu-item-active-color: $primary-dark;
$menu-item-active-background-color: transparent;
$menu-item-hover-background-color: transparent;

// controls
$control-height: 56px;
// $control-padding-horizontal: 20px;
$button-padding-horizontal: 20px;
$button-static-background-color: none;
$button-static-border-color: transparent;
$button-static-color: none;
$button-focus-box-shadow-size: 0;

$dropdown-content-padding-top: 5px;
$dropdown-content-padding-bottom: 5px;
$dropdown-content-background-color: $grey-dark;
$dropdown-item-color: $white;

// screen
$desktop: 1080px;
// $widescreen: 1366px;
$widescreen: 1440px;
$fullhd: 1620px;
