.image {
  img {
    &.is-contain {
      object-fit: contain;
    }

    &.is-cover {
      object-fit: cover;
    }

    &.is-rounded {
      border-radius: $radius-large;
    }

    &.is-overlay {
      height: 100%;
    }
  }

  &.is-overlay {
    position: absolute;
    z-index: -1;

    &:before {
      @extend %overlay;
      display: block;
      content: '';
      z-index: 1;

      background: linear-gradient(
        180deg,
        #c82845 12.5%,
        rgba(200, 40, 69, 0) 100%
      );
    }

    img {
      filter: grayscale(1);
      height: 100%;
    }
  }
}

.logo-list {
  .column {
    flex-basis: auto;
  }

  a {
    &:hover {
      img {
        opacity: 0.5;
      }
    }

    img {
      height: 70px;
    }
  }
}

.columns {
  &.is-scrollable {
    @include touch {
      overflow: auto !important;
      flex-wrap: nowrap !important;
    }
  }

  &.is-variable {
    margin-top: calc(-1 * var(--columnGap));
    margin-bottom: calc(-1 * var(--columnGap));

    &:not(:last-child) {
      margin-bottom: calc(1.5 * var(--columnGap));
    }

    .column {
      padding: var(--columnGap);
    }
  }
}

.logo-main {
  text-decoration: !important;
  position: relative;
  max-width: 350px;
  max-width: 40ch;
  width: 100%;
  flex: 1;

  .logo-icon {
    position: absolute;
    top: 0;
    left: 0;
  }

  .logo-text {
    padding-top: 32px;
    padding-left: 21px;

    p {
      line-height: 1.21;
      color: $white;
      font-weight: $weight-semibold;
      font-size: clamp(0.7188rem, 0.6209rem + 0.4891vi, 1rem);
    }
  }
}
