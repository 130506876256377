.navbar {
  .header-main & {
    border-bottom: 1px solid $primary-darker;

    .navbar-brand {
      margin-left: 0;

      .logo-link {
        flex: 1;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      .navbar-burger {
        margin-right: -$gap;
        width: 64px;

        &:hover,
        &:focus {
          color: $white;
          background: transparent;
        }

        span {
          height: 3px;
          width: 28px;
          border-radius: $radius;
          left: 14px;

          &:nth-child(1) {
            top: 32px;
          }

          &:nth-child(2) {
            top: 38px;
          }

          &:nth-child(3) {
            top: 44px;
          }
        }

        &.is-active {
          span:nth-child(3) {
            transform: translateY(-7px) rotate(-45deg);
          }
        }
      }
    }

    .navbar-menu {
      background: transparent;
      box-shadow: none;

      .navbar-end {
        font-weight: $weight-medium;
        align-items: center;

        .navbar-item,
        .navbar-link {
          font-size: $size-6;
          border-radius: $radius-small;
        }

        > .navbar-item {
          @include desktop {
            &:not(:last-child) {
              margin-right: 24px;
            }
          }

          &.has-dropdown {
            @include desktop {
              &:hover {
                .navbar-link {
                  border-radius: $radius-small $radius-small 0 0;
                }
              }

              .navbar-link {
                background: $primary-darker;

                &:hover {
                  color: $grey-lighter;
                }
              }
            }

            .navbar-dropdown {
              font-size: $size-6;
              border-top-right-radius: 0;
              padding: 8px;
            }
          }
        }
      }
    }
  }
}
