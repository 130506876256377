.button {
  &.is-large {
    height: 70px;
    padding: 24px;
  }

  &.is-white {
    border: 1px solid $border;

    &:hover {
      border-color: $primary;
    }
  }
}
