footer.footer {
  a {
    color: $white;
    text-decoration: underline;

    &.logo-link {
      text-decoration: none;
    }
  }
}
