.box {
  &.is-person {
    .image {
      background: $grey-lighter;
      border-radius: 100px;
      overflow: hidden;
      width: 80px;

      @include tablet {
        width: 100px;
      }

      img {
        filter: grayscale(100%);

        &.is-contain {
          padding: 25px;
        }
      }
    }
  }

  &.is-chart {
    @include mobile {
      margin-left: -24px;
      margin-right: -24px;
      border-radius: 0;
      border-width: 1px 0;
      padding: 1.25rem 0rem;

      h3 {
        padding: 0 16px;
      }
    }

    padding: 1.25rem 1.5rem;
    border: 1px solid $grey-light;
    background: $white;
    box-shadow: 0px 0px 32px -12px rgba(0, 0, 0, 0.1),
      0px 16px 32px -28px rgba(0, 0, 0, 0.25);

    .box-axis {
      display: flex;
      justify-content: space-between;
      padding: 0 16px 8px;
      border-bottom: 1px solid $primary-dark;
    }

    .box-row {
      padding: 8px 16px 16px;
      border-bottom: 1px solid $border;

      &:nth-child(2n) {
        background: $light;
      }

      p {
        margin-bottom: 4px;
      }

      .box-bar {
        display: flex;

        .box-bar-content {
          display: flex;
          align-items: center;
          position: relative;
          font-weight: $weight-semibold;
          flex: none;
          color: $white;
          justify-content: center;
          height: 32px;
          background: $primary;
          white-space: nowrap;
          // min-width: 48px;
        }
      }
    }

    .box-legend {
      padding: 1rem 1rem 0;

      .column {
        max-width: 100%;
        display: flex;

        .box-square {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          flex: none;
        }
      }
    }

    &.is-bar {
      .box-bar-content {
        .is-overflowing {
          position: absolute;
          padding-left: 8px;
          left: 100%;
          color: $primary;

          @include desktop {
            &.is-desktop {
              position: static;
              padding-right: 0;
              color: $white;
            }
          }
        }
      }
    }

    &.is-stacked {
      .box-bar-content {
        .is-overflowing {
          display: none;

          @include desktop {
            &.is-desktop {
              display: block;
            }
          }
        }
      }
    }
  }
}
