.page-results {
  .is-mobile-sticky {
    background: $background;
    z-index: 9;
    position: sticky;
    top: 0;
    margin: -12px -24px 0;
    padding: 12px 24px;

    select {
      padding-left: 16px;
    }
  }

  header.header-main {
    .buttons .button {
      @include mobile {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  aside .menu {
    position: sticky;
    top: 40px;
    padding: 0 0 172px;

    a {
      font-weight: $weight-medium;
      border-left: 1px solid $grey-light;

      &:hover {
        border-color: $black;
      }

      &.is-active {
        border-left: 3px solid $primary-dark;
      }
    }
  }

  article {
    .block-heading_block {
      margin-top: 5rem;

      @include desktop {
        margin-top: 7.5rem;
      }
    }
  }
}

////////////////////////////////////// PDF PRINT
@media print {
  .page-results {
    padding-left: 180px;

    nav.navbar .navbar-menu,
    footer.footer,
    wagtail-userbar,
    .is-hidden-print {
      display: none;
    }

    nav.navbar {
      background: transparent;
      max-width: 80%;
      min-height: auto;
      margin: 0 auto;
      border-bottom: 0;

      .logo-link {
        padding: 0;

        .logo-main {
          svg g {
            fill: $primary-dark;
          }

          .logo-text p {
            color: $primary-dark;
          }
        }
      }
    }

    header.header-main {
      .section.has-background-light {
        background: transparent !important;
      }

      .is-limited-800 {
        max-width: 80%;
        margin: 0 auto;
      }

      .buttons {
        display: none;
      }
    }

    .columns.is-full-print {
      justify-content: center;
      margin: 0;

      .column.is-3 {
        display: none;
      }

      .column.is-9 {
        width: 100%;
        max-width: 80%;
        padding: 0;
      }
    }

    .is-size-2-desktop {
      font-size: $size-2;
      line-height: $lh-2;
    }

    .is-size-3-desktop {
      font-size: $size-3;
      line-height: $lh-3;
    }

    .is-size-4-desktop {
      font-size: $size-4;
      line-height: $lh-4;
    }

    .is-size-6-desktop {
      font-size: $size-6;
      line-height: $lh-6;
    }

    .block-heading_block {
      break-after: avoid;
    }

    .chart-block {
      break-inside: avoid;

      .box.is-chart.is-stacked {
        .box-bar-content {
          .is-overflowing.is-desktop {
            display: block;
          }
        }
      }
    }
  }
}
