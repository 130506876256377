.container {
  &.is-fluid {
    max-width: 1200px !important;
  }

  &.is-narrow {
    max-width: 800px !important;
  }
}

article {
  margin-bottom: 60px;

  @include desktop {
    margin-bottom: 180px;
  }

  h1.title {
    margin: 30px 0 60px;

    @include desktop {
      margin: 120px 0 80px;
    }
  }

  [class^='block-'] {
    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  .block-heading_block {
    margin: 32px 0;
  }

  .block-page_heading_block {
    margin: 120px 0 80px;

    @include desktop {
      margin: 240px 0 120px;
    }
  }

  .block-logo {
    img {
      height: 70px;
    }
  }

  .block-paragraph_block {
    margin: 40px 0;

    & + .block-logo {
      border-top: 1px solid $grey-lighter;
      padding-top: 48px;
      margin-top: 48px;

      @include desktop {
        padding-top: 120px;
        margin-top: 120px;
      }
    }
  }

  .block-emphasis_block {
    margin: 40px 0;

    .box {
      border: 1px solid $border;
      background: $light;
      padding: 1.5rem 2rem;

      p {
        &:first-child {
          color: $primary-dark;
        }

        &:not(:first-child) {
          margin-top: 0.75rem;
          padding-top: 0.75rem;
          border-top: 1px solid $border;
        }
      }

      .button {
        text-align: left;
        justify-content: flex-start;
        white-space: normal;
        height: auto;
        padding: 0 8px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          background: transparent;
        }
      }
    }
  }

  .block-image_block {
    margin: 48px 0;
  }

  .block-chart_block {
    margin: 1.5rem 0;

    > h3 {
      margin-top: 2.5rem;
    }
  }

  .block-footnotes {
    border-top: 1px solid $black;
    margin-top: 40px;

    @include tablet {
      margin-top: 120px;
    }

    p {
      margin-top: 8px;
    }
  }
}

.is-limited {
  &-800 {
    max-width: 800px;
  }
}
